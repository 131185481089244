import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GeneralConfigService } from '@core/services/general-config.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigResolver {
  constructor(
    private readonly generalConfigService: GeneralConfigService,
    private readonly translationService: TranslateService,
  ) {}

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    let header = '';

    if (route?.data?.generalConfig?.mainHeader !== '') {
      header = this.translationService.instant(route.data.generalConfig.mainHeader);
    }

    this.generalConfigService.setGeneralConfig({
      mainHeader: header,
      backUrl: route?.data?.generalConfig?.backUrl,
      showNavigationBar: route?.data?.generalConfig?.showNavigationBar,
    });
  }
}
